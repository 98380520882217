import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import { AiOutlineSearch } from "react-icons/ai";
import classes from "../../assets/css/Gallery.module.css";
import { Link } from "react-router-dom";
import dImg1 from "../../assets/img/p1.jpg";
import dImg3 from "../../assets/img/showCase.jpg";
import "../../assets/css/addproduct.css";
import GalleryImgCard from "./GalleryImgCard";
import GalleryvideoCard from './GalleryvideoCard';
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios'
// DUMMY DATA
const dummy = [
  {
    img: dImg1,
    title: "Test",
  },

  {
    img: dImg3,
    title: "Test",
  },
];

const GalleryMain = () => {
  const [imgurl, setimgurl] = useState(null)
  const [type, settype] = useState(false)
  const [images, setimages] = useState([])
  const [videos, sevideos] = useState([])
  const getimgurl = async () => {
    var file = document.getElementById("image").files;
    if (file.length !== 0) {
      let file11 = new Promise((resolve) => {
        toast.info("Please Wait ...", {
          autoClose: 3000,
          transition: Slide,
        });
        var storageRef = firebase.storage().ref("profile/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            //img download link ah ketakiradhu
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl = await file11;
      console.log(imgurl)
      setimgurl(imgurl)
    }
  }
  const submitimgbtn = async () => {

    if (type === false) {
      var name = document.getElementById("name").value
      var data = {
        image: imgurl,
        name: name
      }
      var response = await axios.post(`${process.env.REACT_APP_SERVER}/image/create`, data).then((res) => { return res.data })
      if (response !== null) {
        alert("Image Added Successfull...")
        window.location.replace("/gallery")
      }
    } else {
      var name1 = document.getElementById("name").value
      var data1 = {
        video: imgurl,
        name: name1
      }
      var response1 = await axios.post(`${process.env.REACT_APP_SERVER}/video/create`, data1).then((res) => { return res.data })
      if (response1 !== null) {
        alert("Video Added Successfull...")
        window.location.replace("/gallery")
      }
    }

  }
  useEffect(() => {
    getgallerydata()
  }, [])
  const getgallerydata = async () => {
    var images = await axios.get(`${process.env.REACT_APP_SERVER}/image/`).then((res) => { return res.data })
    var videos = await axios.get(`${process.env.REACT_APP_SERVER}/video/`).then((res) => { return res.data })
    console.log(images, videos)
    setimages(images)
    sevideos(videos)
  }
  return (
    <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1}>
      <div className="row">
        <div className="col-sm-2">
          <h3>Gallery</h3>
        </div>
        <div className="col-sm-2" style={{ marginLeft: "10px" }}>
          <button
            variant="contained"
            id="addbtn"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            data-bs-toggle="modal" data-bs-target="#exampleModal"
          >
            <span className="linktag">
              Add New
            </span>{" "}
          </button>
        </div>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Photo</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="material-textfield mt-4">
                <input
                  type="file"
                  variant="outlined"
                  className="pname"
                  name="pname"
                  id="image"
                  onChange={getimgurl}
                />
                <label className="formlabel">Photo</label>
              </div>
              <div className="material-textfield mt-4">
                <input
                  type="text"
                  variant="outlined"
                  className="pname"
                  name="pname"
                  id="name"
                />
                <label className="formlabel">Name</label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

              {imgurl !== null ? <button type="button" class="btn btn-primary" onClick={submitimgbtn}>submit</button> : null}

            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-5">
          <div className="row">
            <div className="col-md-6">
              {type !== true ? <button className="employee__button" onClick={() => settype(false)}>
                <span
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Photos
                </span>
              </button> : <button className="student__button" onClick={() => settype(false)}>
                <span
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Photos
                </span>
              </button>}

            </div>
            <div className="col-md-6">
              {type !== true ? <button className="student__button" onClick={() => settype(true)}>
                <span
                  style={{ color: "black", textDecoration: "none" }}
                >
                  video
                </span>
              </button> : <button className="employee__button" onClick={() => settype(true)}>
                <span
                  style={{ color: "black", textDecoration: "none" }}
                >
                  video
                </span>
              </button>}

            </div>
          </div>
        </div>
        {type === false ?
          <div className="col-md-10" id="productdiv">
            <div className={classes.GalleryImgContainer}>

              {images.length !== 0 ? images.map((item) => {
                return <GalleryImgCard img={item.image} title={item.name} />;
              }) : null}
            </div>
          </div> :
          <div className="col-md-10" id="productdiv">
            <div className={classes.GalleryImgContainer}>
              {videos.length !== 0 ? videos.map((item) => {
                return <GalleryvideoCard video={item.video} title={item.name} />;
              }) : null}
            </div>
          </div>
        }

      </div>
      <ToastContainer />
    </Box>
  );
};

export default GalleryMain;
