import React from 'react'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { AiOutlineSearch } from 'react-icons/ai'
import { RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import { Link } from 'react-router-dom';
import "../../assets/css/addproduct.css"
import TextField from '@material-ui/core/TextField';
import axios from "axios"

const Addtransport = () => {

    const addtransport = async () => {
        var vechileno = document.getElementById("vechileno").value
        var capacity = document.getElementById("capacity").value
        var drivername = document.getElementById("drivername").value
        var vechiletype = document.getElementById("vechiletype").value
        var occupied = document.getElementById("occupied").value
        var permitstartdate = document.getElementById("permitstartdate").value
        var permitenddate = document.getElementById("permitenddate").value

        var data = {
            vechileno: vechileno,
            capacity: capacity,
            drivername: drivername,
            vechiletype: vechiletype,
            occupied: occupied,
            permitstartdate: permitstartdate,
            permitenddate: permitenddate
        }
        var response = await axios.post(`${process.env.REACT_APP_SERVER}/transport/create`, data).then((res) => { return res.data })
        if (response !== null) {
            alert("Transport Added Successfull...")
            window.location.replace("/transport")
        }

    }
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >

            <div className="row">
                <div className="col-md-12" id="productdiv">
                    <div className="row ">
                        <div className='col-md-10'>
                            <div className='emp__subheading'>Vehicles</div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <span className='righticon' >
                                    <RiFileExcelLine />
                                </span>
                                <span className="righticon">
                                    <BsPrinter />
                                </span>
                            </div>
                        </div>


                    </div>
                    <div className='row'>
                        <div className='col-md-3 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="vechileno"
                                />
                                <label className="formlabel">Vechile Number</label>
                            </div>
                        </div>
                        <div className='col-md-3 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="capacity"
                                />
                                <label className="formlabel">Total Capacity</label>
                            </div>
                        </div>
                        <div className='col-md-3  box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="drivername"
                                />
                                <label className="formlabel">Driver Name</label>
                            </div>
                        </div>
                        <div className='col-md-3'></div>
                    </div>
                    <div className="row">
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="vechiletype"
                                />
                                <label className="formlabel">Vechile Type</label>
                            </div>
                        </div>
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="occupied"
                                />
                                <label className="formlabel">Occupied</label>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 box1'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="date"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="permitstartdate"
                                />
                                <label className="formlabel">Permit Start Date</label>
                            </div>
                        </div>
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="date"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="permitenddate"
                                />
                                <label className="formlabel">Permit End Date</label>
                            </div>
                        </div>
                        <div className='col-md-4 box'>


                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-3 box'>
                            <Button variant="contained" color="primary" onClick={addtransport}>
                                Add Block
                            </Button>
                        </div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                    </div>


                </div>
            </div>

        </Box>
    )
}

export default Addtransport