import React, { Component } from 'react'
import "../assets/css/sidebar.css"
import logo from '../assets/img/university logo.png'
import { NavLink } from 'react-router-dom';
import { MdDashboard } from 'react-icons/md'
import { AiFillTag, AiOutlineSearch, AiOutlineCalendar, AiOutlineTag, AiFillFileText } from 'react-icons/ai'
import { BiWindow } from 'react-icons/bi'
import "animate.css"
import { BsTools, BsLayoutTextWindow } from "react-icons/bs"
import styles from "../assets/css/defaultLogin.module.css";
export default class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isbigbar: true,
            listitem: false,
            partiesitem: false,
            companyitem: false,
            presaleitem: false,
            inventryitem: false,
            purchaseitem: false,
            attentenceitem: false
        }
    }
    changeBar = () => this.setState({ isbigbar: !this.state.isbigbar })
    listitem = () => {
        const { listitem } = this.state
        if (listitem === true) {
            this.setState({
                listitem: false
            })
        } else {
            this.setState({
                listitem: true
            })
        }
    }
    partiesitem = () => {
        const { partiesitem } = this.state
        if (partiesitem === true) {
            this.setState({
                partiesitem: false
            })
        } else {
            this.setState({
                partiesitem: true
            })
        }
    }
    companyitem = () => {
        const { companyitem } = this.state
        if (companyitem === true) {
            this.setState({
                companyitem: false
            })
        } else {
            this.setState({
                companyitem: true
            })
        }
    }
    presaleitem = () => {
        const { presaleitem } = this.state
        if (presaleitem === true) {
            this.setState({
                presaleitem: false
            })
        } else {
            this.setState({
                presaleitem: true
            })
        }
    }
    inventryitem = () => {
        const { inventryitem } = this.state
        if (inventryitem === true) {
            this.setState({
                inventryitem: false
            })
        } else {
            this.setState({
                inventryitem: true
            })
        }
    }
    purchaseitem = () => {
        const { purchaseitem } = this.state
        if (purchaseitem === true) {
            this.setState({
                purchaseitem: false
            })
        } else {
            this.setState({
                purchaseitem: true
            })
        }
    }
    attentenceitem = () => {
        const { attentenceitem } = this.state
        if (attentenceitem === true) {
            this.setState({
                attentenceitem: false
            })
        } else {
            this.setState({
                attentenceitem: true
            })
        }
    }
    logoutbtn = () => {
        sessionStorage.clear()
        localStorage.clear()
        window.location.replace("/")
    }
    render() {
        const { isbigbar, listitem, purchaseitem, attentenceitem } = this.state
        return (
            <div className={`${isbigbar ? 'sidebar-div' : 'small-sidebar-div'}`} >
                <div className="logo-div">
                    <h3 onClick={this.changeBar} className="text-center text-white" style={{ cursor: "pointer" }}><img className="logoimg" src={logo} alt="" /></h3>

                    {isbigbar ? <div className="p-2">
                        <div className="form-group has-searchside">
                            <AiOutlineSearch className="fa fa-search form-control-feedbackside" />
                            <input type="text" className="form-control" id="searchform" placeholder="Search" />
                        </div>
                    </div> : null}
                    <ul className="mt-5">
                        <li><span id="border"></span><NavLink className="navlinkli" activeClassName="activenavlink" exact to="/"><span className="iconspan"><MdDashboard /></span>{isbigbar ? <span className="spanname animate__backInLeft" >Dashboard</span> : null}</NavLink></li>
                        <li onClick={this.listitem}><NavLink className="navlinkli" activeClassName="/" exact to="/employee"><span className="iconspan"><BsTools /></span>{isbigbar ? <span className="spanname animate__backInLeft">Registration</span> : null}</NavLink></li>
                        {listitem === true ?
                            <ul>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/student"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Student Registation</span> : null}</NavLink></li>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/employee"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Employee Registation</span> : null}</NavLink></li>

                            </ul> : null}
                        <li ><NavLink className="navlinkli" activeClassName="/" exact to="/management"><span className="iconspan"><AiFillFileText /></span>{isbigbar ? <span className="spanname animate__backInLeft">Management</span> : null}</NavLink></li>
                        <li onClick={this.attentenceitem}><NavLink className="navlinkli" activeClassName="/" exact to="##"><span className="iconspan"><AiFillFileText /></span>{isbigbar ? <span className="spanname animate__backInLeft">Attendance</span> : null}</NavLink></li>

                        {attentenceitem === true ?
                            <ul>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/StudentAttendance"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Student Attendance</span> : null}</NavLink></li>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/EmployeeAttendance"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Employee Attendance</span> : null}</NavLink></li>

                            </ul> : null}
                        <li onClick={this.purchaseitem}><NavLink className="navlinkli" activeClassName="/" exact to="##"><span className="iconspan"><AiOutlineTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Fees</span> : null}</NavLink></li>
                        {purchaseitem === true ?
                            <ul>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/InventorySuppliers"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Inventory Suppliers</span> : null}</NavLink></li>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/Productcatalog"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Product</span> : null}</NavLink></li>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/Prepurchase"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Pre Purchase</span> : null}</NavLink></li>
                                <li><NavLink className="navlinkli" activeClassName="/" exact to="/Purchaseorder"><span className="iconspan"><AiFillTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Purchase Order</span> : null}</NavLink></li>

                            </ul> : null
                        }


                        <li><NavLink className="navlinkli" activeClassName="/" exact to="/hostel"><span className="iconspan"><BiWindow /></span>{isbigbar ? <span className="spanname animate__backInLeft">Hostel</span> : null}</NavLink></li>
                        <li><NavLink className="navlinkli" activeClassName="/" exact to="/gallery"><span className="iconspan"><BsLayoutTextWindow /></span>{isbigbar ? <span className="spanname animate__backInLeft">Gallery</span> : null}</NavLink></li>
                        <li><NavLink className="navlinkli" activeClassName="/" exact to="/"><span className="iconspan"><BsLayoutTextWindow /></span>{isbigbar ? <span className="spanname animate__backInLeft">Mess</span> : null}</NavLink></li>
                        <li><NavLink className="navlinkli" activeClassName="/" exact to="/transport"><span className="iconspan"><AiOutlineTag /></span>{isbigbar ? <span className="spanname animate__backInLeft">Transport</span> : null}</NavLink></li>
                        <li><NavLink className="navlinkli" activeClassName="/" exact to="/users"><span className="iconspan"><AiOutlineCalendar /></span>{isbigbar ? <span className="spanname animate__backInLeft">Time Table</span> : null}</NavLink></li>

                        <li onClick={this.logoutbtn}><NavLink className="navlinkli" activeClassName="/" exact to="##"><span className="iconspan"><AiOutlineCalendar /></span>{isbigbar ? <span className="spanname animate__backInLeft">Logout</span> : null}</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}
