import React, { Component } from "react";
import {
  Employee, Student, AddStudent, Gallery,
  AddEmployee, Hostel, Addhostel, Addtransport,
  Transport, Attendance, Management, EmployeeAttendance,
  Department,Division
} from "../pages";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard/dashboard";

export default class Index extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/employee" component={Employee} />
        <Route exact path="/addemployee" component={AddEmployee} />
        <Route exact path="/student" component={Student} />
        <Route exact path="/addstudent" component={AddStudent} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/hostel" component={Hostel} />
        <Route exact path="/addhostel" component={Addhostel} />
        <Route exact path="/transport" component={Transport} />
        <Route exact path="/addtransport" component={Addtransport} />
        <Route exact path="/StudentAttendance" component={Attendance} />
        <Route exact path="/EmployeeAttendance" component={EmployeeAttendance} />
        <Route exact path="/management" component={Management} />
        <Route exact path="/department" component={Department} />
        <Route exact path="/division" component={Division} />
        <Route exact path="/" component={Dashboard} />
      </Switch>
    );
  }
}
