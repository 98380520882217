import React from "react";
import "../assets/css/layout.css";
import { Sidebar } from "../pages";
import Router from "../router";
import "animate.css";
import { useSelector } from "react-redux";
import Login from "../pages/Login";
const Index = () => {
  const isLogin = useSelector((store) => store.isLogin);
  const main = (
    <>
      <div className="layout-div">
        <div className="layout-sidebar-div">
          <Sidebar />
        </div>
        <div className="layout-body card">
          <Router />
        </div>
      </div>
    </>
  );
  console.log(isLogin);
  return (
    <div>
      {isLogin && main}
      {!isLogin && <Login />}
    </div>
  );
};

export default Index;
