import React, { Component } from 'react'
import Layout from "./layout"
export default class App extends Component {
    render() {
        return (
            <React.Fragment>
                <Layout />
            </React.Fragment>
        )
    }
}
