import React from 'react'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import granite4 from "../../assets/img/granite4.jpg"
import { AiOutlineSearch, AiFillInfoCircle, AiOutlineFileText, } from 'react-icons/ai'
import { RiDeleteBin6Line, RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import { MdEdit } from "react-icons/md"
import { IoMdShareAlt } from "react-icons/io"
import { BiBarChartAlt2 } from "react-icons/bi"
import { Link } from 'react-router-dom';
import "../../assets/css/addproduct.css"
import { useEffect, useState } from "react"
import axios from "axios"

const Transport = () => {
    const [trsnsportdata, settrsnsportdata] = useState([])
    useEffect(() => {
        getdata()
    }, [])

    const getdata = async () => {
        var response = await axios.get(`${process.env.REACT_APP_SERVER}/transport`).then((res) => { return res.data })
        settrsnsportdata(response)
    }
    const searchname = (e) => {
        if (e.target.value.length !== 0) {
            var data = []
            for (var i = 0; i < trsnsportdata.length; i++) {
                if (trsnsportdata[i].vechileno.toLowerCase().includes(e.target.value.toLowerCase()) || trsnsportdata[i].drivername.toLowerCase().includes(e.target.value.toLowerCase())) {
                    data.push(trsnsportdata[i])
                }
            }
            settrsnsportdata(data)
        } else {
            getdata()
        }
    }
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >
            <div className="row">
                <div className="col-sm-2">
                    <h3>Transport</h3>
                </div>
                <div className='col-sm-2'></div>
                <div className="col-sm-4 searchinput">
                    <div className="form-group has-search">
                        <AiOutlineSearch className="fa fa-search form-control-feedback" />
                        <input type="text" className="form-control" placeholder="Search By Driver Name,Vehicle Number " onChange={searchname} />
                    </div>
                </div>
                <div className="col-sm-2" style={{ marginLeft: "10px" }}>
                    <button variant="contained" id="addbtn" color="primary" size="small" startIcon={<AddIcon />}><Link to="/addtransport" className="linktag">Add New</Link> </button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-5">

                    <div className="row">
                        <div className="col-md-6">
                            <button className="student__button">
                                <Link
                                    style={{ color: "black", textDecoration: "none" }}
                                    to="/Student"
                                >
                                    Student
                                </Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button className="employee__button">
                                <Link
                                    style={{ color: "white", textDecoration: "none" }}
                                    to="/transport"
                                >
                                    Transport
                                </Link>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="col-md-10" id="productdiv">
                    <div className="row ">
                        <div className='col-md-10'>
                            <div className='emp__subheading'>Vehicles</div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <span className='righticon' >
                                    <RiFileExcelLine />
                                </span>
                                <span className="righticon">
                                    <BsPrinter />
                                </span>
                            </div>
                        </div>


                    </div>
                    <div className='row table__less'>
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Vehicle Number</th>
                                    <th>Vehicle Type</th>
                                    <th>Driver Name</th>
                                    <th>Capacity</th>
                                    <th>Ouccpied</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trsnsportdata.length !== 0 ? trsnsportdata.map((data, index) => (
                                    <tr key={index}>
                                        <td> {index + 1}</td>
                                        <td>{data.vechileno}</td>
                                        <td>{data.vechiletype}</td>
                                        <td>{data.drivername}</td>
                                        <td>{data.capacity}</td>
                                        <td>{data.occupied}</td>
                                        <td>Available</td>
                                        <td><span className='icons__black' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><AiFillInfoCircle /></span><span className='icons__orange'><AiOutlineFileText /></span><span className="icons__red"><RiDeleteBin6Line /></span></td>

                                    </tr>
                                )) : null}

                            </tbody>
                        </table>
                    </div>
                    <div class="offcanvas offcanvas-end" style={{ width: "600px" }} tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div class="offcanvas-header">
                            <h5 id="offcanvasRightLabel">Student Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <img className='profile__image' alt="" src={granite4} />
                                    </div>
                                    <div className='col-md-9'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <span className='icons__box'><MdEdit /></span><span className='icons__box'><BsPrinter /></span><span className='icons__box'><IoMdShareAlt /></span><span className="icons__box1"><BiBarChartAlt2 /></span>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <h5><b>Student Name</b></h5>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Enroll No : <span className="firstlineamount"><b>12455</b> </span><span></span></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Gender : <span className="firstlineamountnew"><b>Male</b></span> <span></span></p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Scholar No :<span className="firstlineamount"> <b>B.ARCH</b></span><span></span></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Age : <span className="firstlineamountnew"><b>20</b></span> <span></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Personal</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Medical</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Documents</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Relation</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Experience</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Mark History</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Others</button>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Father Name</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Mother Name</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Caster Category</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Religion</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Birth Date</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Admission Category</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Course Level</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Admission In Class</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Current Class</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mt-1'>
                                        <label htmlFor="">Admission Date</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <label htmlFor="">Course</label>
                                        <input type="text" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="offcanvas offcanvas-end" style={{ width: "600px" }} tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div class="offcanvas-header">
                            <h5 id="offcanvasRightLabel">Student Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <img className='profile__image' alt="" src={granite4} />
                                    </div>
                                    <div className='col-md-9'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <span className='icons__box'><MdEdit /></span><span className='icons__box'><BsPrinter /></span><span className='icons__box'><IoMdShareAlt /></span><span className="icons__box1"><BiBarChartAlt2 /></span>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <h5><b>Student Name</b></h5>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Enroll No : <span className="firstlineamount"><b>12455</b> </span><span></span></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Gender : <span className="firstlineamountnew"><b>Male</b></span> <span></span></p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Scholar No :<span className="firstlineamount"> <b>B.ARCH</b></span><span></span></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="productdetailsitem">Age : <span className="firstlineamountnew"><b>20</b></span> <span></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Personal</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Medical</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Documents</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Relation</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Experience</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Mark History</button>
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <button className='student__button'>Others</button>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Father Name</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Mother Name</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Caster Category</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Religion</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Birth Date</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Admission Category</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Course Level</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Admission In Class</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-4 mt-1'>
                                        <label htmlFor="">Current Class</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mt-1'>
                                        <label htmlFor="">Admission Date</label>
                                        <input type="text" />
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <label htmlFor="">Course</label>
                                        <input type="text" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box >
    )
}

export default Transport