import React, { useEffect, useState } from "react";
import { Box } from '@material-ui/core';
import '../../assets/css/addproduct.css'
import Avatar from '@material-ui/core/Avatar';
import demo from '../../assets/img/demoimg.webp'
import { firebase } from "../../database/firebase";
import axios from 'axios'

const AddStudent = () => {
  const [imageurl, setimageurl] = useState(null);
  const [imageurl2, setimageurl2] = useState(null);
  const [coursedata, setcoursedata] = useState([]);
  const [departmentdata, setdepartmentdata] = useState([]);
  const [divisiondata, setdivisiondata] = useState([]);
  const getimgaeurl = async () => {
    var file = document.getElementById("image").files;
    let file11 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("profile/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl = await file11;
    setimageurl(imgurl)
  }
  const getimgaeurl2 = async () => {
    var file2 = document.getElementById("image2").files;
    let file22 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("profile/" + file2[0].name);
      storageRef.put(file2[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl2 = await file22
    setimageurl2(imgurl2)
  }
  const savestudent = async () => {
    var formsession = document.getElementById("formsession").value
    var campus = document.getElementById("campus").value
    var institute = document.getElementById("institute").value
    var formname = document.getElementById("formname").value
    var formno = document.getElementById("formno").value
    var enquiryno = document.getElementById("enquiryno").value
    var telenquiryno = document.getElementById("telenquiryno").value
    var scholarno = document.getElementById("scholarno").value
    var lastscholarno = document.getElementById("lastscholarno").value
    var enrollno = document.getElementById("enrollno").value
    var onlineadmissionno = document.getElementById("onlineadmissionno").value
    var firstname = document.getElementById("firstname").value
    var middlename = document.getElementById("middlename").value
    var lastname = document.getElementById("lastname").value
    var bloodgroup = document.getElementById("blooadgroup").value
    var gender = document.getElementById("gender").value
    var fathername = document.getElementById("fathername").value
    var annualincome = document.getElementById("annualincome").value
    var shiftingreason = document.getElementById("shiftingreason").value
    var mothername = document.getElementById("mothername").value
    var consultant = document.getElementById("consultant").value
    var house = document.getElementById("house").value
    var previousinstitute = document.getElementById("previousinstitute").value
    var university = document.getElementById("university").value
    var department = document.getElementById("department").value
    var course = document.getElementById("course").value
    var division = document.getElementById("division").value
    var courseopted = document.getElementById("courseopted").value
    var fees = document.getElementById("fees").value
    var feescate = document.getElementById("feescate").value
    var feesplan = document.getElementById("feesplan").value
    var caste = document.getElementById("caste").value
    var religion = document.getElementById("religion").value
    var birthday = document.getElementById("birthday").value
    var admissioncategory = document.getElementById("admissioncategory").value
    var admissiondate = document.getElementById("admissiondate").value
    var enrolldate = document.getElementById("enrolldate").value
    var phone = document.getElementById("phone").value
    var email = document.getElementById("email").value
    var pan = document.getElementById("pan").value
    var parentsphone = document.getElementById("parentsphone").value
    var aadhar = document.getElementById("aadhar").value
    var placeofbirth = document.getElementById("placeofbirth").value
    var nationality = document.getElementById("nationality").value
    var studentaliasname = document.getElementById("studentaliasname").value
    var address = document.getElementById("address").value
    var city = document.getElementById("city").value
    var state = document.getElementById("state").value
    var country = document.getElementById("country").value
    var pincode = document.getElementById("pincode").value
    var data = {
      formsession: formsession,
      campus: campus,
      institute: institute,
      formname: formname,
      formno: formno,
      enquiryno: enquiryno,
      telephoneno: telenquiryno,
      scholarno: scholarno,
      lastscholarno: lastscholarno,
      enrollno: enrollno,
      onlineadmissionno: onlineadmissionno,
      firstname: firstname,
      middlename: middlename,
      lastname: lastname,
      bloodgroup: bloodgroup,
      gender: gender,
      fathername: fathername,
      annualincome: annualincome,
      mothername: mothername,
      previousinstitute: previousinstitute,
      shitingreason: shiftingreason,
      consultant: consultant,
      house: house,
      university: university,
      department: department,
      course: course,
      division: division,
      courseopted: courseopted,
      feesession: fees,
      feecategory: feescate,
      feesplan: feesplan,
      caste: caste,
      religion: religion,
      birthdate: birthday,
      admissioncategory: admissioncategory,
      admissiondate: admissiondate,
      enrolldate: enrolldate,
      phoneno: phone,
      email: email,
      panno: pan,
      parentmobile: parentsphone,
      aadharno: aadhar,
      placeofbirth: placeofbirth,
      nationality: nationality,
      country: country,
      state: state,
      city: city,
      pincode: pincode,
      studentstatus: "Current",
      studentnamealias: studentaliasname,
      address: address,
      profile: imageurl,
      signature: imageurl2
    }
    var createstudent = await axios.post(`${process.env.REACT_APP_SERVER}/student`, data).then((res) => { return res.data })
    if (createstudent !== null) {
      alert("Student Added...")
      window.location.replace("/student")
    }
  }
  useEffect(() => {
    getdata()
  }, [])
  const getdata = async () => {
    var allcourse = await axios.get(`${process.env.REACT_APP_SERVER}/course/`).then((res) => { return res.data })
    setcoursedata(allcourse)
  }
  const getdepartment = async (e) => {
    var alldepartment = await axios.get(`${process.env.REACT_APP_SERVER}/department/`).then((res) => { return res.data })
    var mydepartment = await alldepartment.filter((data) => { return data.course === e.target.value })
    setdepartmentdata(mydepartment);
  }
  const getdivisiont = async (e) => {
    var alldivision = await axios.get(`${process.env.REACT_APP_SERVER}/division/`).then((res) => { return res.data })
    var mydepartment = await alldivision.filter((data) => { return data.departmentname === e.target.value })
    setdivisiondata(mydepartment);
  }
  return (
    <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >
      <h6 className="divtitle">Add Students</h6>
      <div className='row'>
        <div className="col-md-2">
          {imageurl === null ? <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> : <Avatar alt="Remy Sharp" src={imageurl} />}
          <div className="material-textfield mt-4">
            <input placeholder="" className="pname" name='pname' id='image' type="file" onChange={getimgaeurl} />
            <label className="formlabel">Profile Picture</label>
          </div>
        </div>
        <div className="col-md-2">
          {imageurl2 === null ? <img src={demo} alt="" srcset="" style={{ width: "48px" }} /> : <Avatar alt="Remy Sharp" src={imageurl2} />}
          <div className="material-textfield mt-4">
            <input placeholder="" className="pname" name='pname' id='image2' type="file" onChange={getimgaeurl2} />
            <label className="formlabel">Signature</label>
          </div>
        </div>

      </div>
      <div className="row mt-4">
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='formsession'>
              <option >Select One</option>
              <option value="2000-2001">2000-2001</option>
              <option value="2001-2002">2001-2002</option>
              <option value="2002-2003">2002-2003</option>
              <option value="2003-2004">2003-2004</option>
              <option value="2004-2005">2004-2005</option>
              <option value="2005-2006">2005-2006</option>
              <option value="2006-2007">2006-2007</option>
              <option value="2007-2008">2007-2008</option>
              <option value="2008-2009">2008-2009</option>
              <option value="2009-2010">2009-2010</option>
              <option value="2010-2011">2010-2011</option>
              <option value="2011-2012">2011-2012</option>
              <option value="2012-2013">2012-2013</option>
              <option value="2013-2014">2013-2014</option>
              <option value="2014-2015">2014-2015</option>
              <option value="2015-2016">2015-2016</option>
              <option value="2016-2017">2016-2017</option>
              <option value="2017-2018">2017-2018</option>
              <option value="2018-2019">2018-2019</option>
              <option value="2019-2020">2019-2020</option>
              <option value="2020-2021">2020-2021</option>
              <option selected="selected" value="2021-2022">2021-2022</option>
              <option value="2022-2023">2022-2023</option>
              <option value="2023-2024">2023-2024</option>
              <option value="2024-2025">2024-2025</option>
              <option value="2025-2026">2025-2026</option>
              <option value="2026-2027">2026-2027</option>
              <option value="2027-2028">2027-2028</option>
              <option value="2028-2029">2028-2029</option>
              <option value="2029-2030">2029-2030</option>
            </select>
            <label className="formlabel">Form Session</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='campus'>
              <option >Select </option>
              <option value="AAYOJAN SCHOOL OF ARCHITECTURE">AAYOJAN SCHOOL OF ARCHITECTURE</option>
            </select>
            <label className="formlabel">Campus</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='institute'>
              <option >Select </option>
              <option value="AAYOJAN SCHOOL OF ARCHITECTURE">AAYOJAN SCHOOL OF ARCHITECTURE</option>
            </select>
            <label className="formlabel">Institute</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='formname'>
              <option >Select </option>
            </select>
            <label className="formlabel">Form Name</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='formno' />
            <label className="formlabel">Form No</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='enquiryno' />
            <label className="formlabel">Enquiry No</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='telenquiryno' />
            <label className="formlabel">Telephone Enquiry No</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='scholarno' />
            <label className="formlabel">Scholar No</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' defaultValue="ASA/2020/M.ARCH./05" type="text" id='lastscholarno' />
            <label className="formlabel">Last Scholar No</label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='enrollno' />
            <label className="formlabel">Enroll No</label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='onlineadmissionno' />
            <label className="formlabel">Onlone Admisssion Form No</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='firstname' />
            <label className="formlabel">First Name</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='middlename' />
            <label className="formlabel">Middle Name</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='lastname' />
            <label className="formlabel">Last Name</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='blooadgroup'>
              <option value="">Select Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
            </select>
            <label className="formlabel">Blood Group</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='gender'>
              <option value="">Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="T">Transgender</option>
              <option value="O">Other</option>
            </select>
            <label className="formlabel">Gender</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='fathername' />
            <label className="formlabel">Father Name</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='annualincome' />
            <label className="formlabel">Annual Income</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='shiftingreason' />
            <label className="formlabel">Shifting Reason</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='mothername' />
            <label className="formlabel">Mother Name</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='consultant'>
              <option>Select</option>
              <option value="GLOBAL COMPUTECH JAIPUR">GLOBAL COMPUTECH JAIPUR</option>
              <option value="PN ASSOCIATES">PN ASSOCIATES</option>
              <option value="R K. ENGINEERING WORKS">R K. ENGINEERING WORKS</option>
              <option value="PAL ASSOCIATES">PAL ASSOCIATES</option>
              <option value="FALGUNI ENTERPRISES">FALGUNI ENTERPRISES</option>
              <option value="ALLIED SALES AGENCIES">ALLIED SALES AGENCIES</option>
              <option value="B.M. INFOTRADE PVT. LTD.">B.M. INFOTRADE PVT. LTD.</option>
              <option value="O.R. AGENCY">O.R. AGENCY</option>
              <option value="FRONTLINE SOLUTIONS">FRONTLINE SOLUTIONS</option>
              <option value="O.B.M. ELECTRONICS &amp; TECHNOLOGY LTD.">O.B.M. ELECTRONICS &amp; TECHNOLOGY LTD.</option>
              <option value="AMAZON">AMAZON</option>
            </select>
            <label className="formlabel">Consultant</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='house'>
              <option>Select</option>
              <option value="ADR">ADR</option>
              <option value="APK">APK</option>
              <option value="CC">CC</option>
              <option value="LB">LB</option>
            </select>
            <label className="formlabel">House</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='previousinstitute' />
            <label className="formlabel">Previous Institute</label>
          </div>
        </div>
      </div>
      <hr />
      <h6>Class Details</h6>
      <div className="row mt-4">
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='university'>
              <option >Select </option>
              <option value="Rajasthan Technical University">Rajasthan Technical University</option>
            </select>
            <label className="formlabel">University</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='course' onChange={getdepartment}>
              <option >Select </option>
              {coursedata.length !== 0 ? coursedata.map((data, index) => (
                <option value={data.coursename} key={index}>{data.coursename}</option>
              )) : null}

            </select>
            <label className="formlabel">Course</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='department' onChange={getdivisiont}>
              <option >Select </option>
              {departmentdata.length !== 0 ? departmentdata.map((data, index) => (
                <option value={data.departmentname} key={index}>{data.departmentname}</option>
              )) : null}
            </select>
            <label className="formlabel">Department</label>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='division'>
              <option >Select </option>
              {divisiondata.length !== 0 ? divisiondata.map((data, index) => (
                <option value={data.division} key={index}>{data.division}</option>
              )) : null}
            </select>
            <label className="formlabel">Division</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='courseopted'>
              <option >Select </option>
              <option value="B.Arch.">B.Arch.</option>
              <option value="CECE">CE</option>
              <option value="CSE">CSE</option>
              <option value="ECE">ECE</option>
              <option value="EEE">EEE</option>
              <option value="IT">IT</option>
              <option value="M.Arch.">M.Arch.</option>
              <option value="ME">ME</option>
            </select>
            <label className="formlabel">Course Opted</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='fees'>
              <option >Select </option>
              <option value="2000-2001">2000-2001</option>
              <option value="2001-2002">2001-2002</option>
              <option value="2002-2003">2002-2003</option>
              <option value="2003-2004">2003-2004</option>
              <option value="2004-2005">2004-2005</option>
              <option value="2005-2006">2005-2006</option>
              <option value="2006-2007">2006-2007</option>
              <option value="2007-2008">2007-2008</option>
              <option value="2008-2009">2008-2009</option>
              <option value="2009-2010">2009-2010</option>
              <option value="2010-2011">2010-2011</option>
              <option value="2011-2012">2011-2012</option>
              <option value="2012-2013">2012-2013</option>
              <option value="2013-2014">2013-2014</option>
              <option value="2014-2015">2014-2015</option>
              <option value="2015-2016">2015-2016</option>
              <option value="2016-2017">2016-2017</option>
              <option value="2017-2018">2017-2018</option>
              <option value="2018-2019">2018-2019</option>
              <option value="2019-2020">2019-2020</option>
              <option value="2020-2021">2020-2021</option>
              <option value="2021-2022">2021-2022</option>
              <option value="2022-2023">2022-2023</option>
              <option value="2023-2024">2023-2024</option>
              <option value="2024-2025">2024-2025</option>
              <option value="2025-2026">2025-2026</option>
              <option value="2026-2027">2026-2027</option>
              <option value="2027-2028">2027-2028</option>
              <option value="2028-2029">2028-2029</option>
              <option value="2029-2030">2029-2030</option>
            </select>
            <label className="formlabel">Fees Session</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='feescate'>
              <option >Select </option>
              <option value="General">General</option>
              <option value="LEET">LEET</option>
              <option value="Reap">Reap</option>
            </select>
            <label className="formlabel">Fees Category</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='feesplan'>
              <option >Select </option>
            </select>
            <label className="formlabel">Fees Plan</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='caste'>
              <option >Select </option>
              <option value="DEC ST EMPL WARD">DEC ST EMPL WARD</option>
              <option value="General">General</option>
              <option value="GENERAL (MINORITY)">GENERAL (MINORITY)</option>
              <option value="Jain Minority">Jain Minority</option>
              <option value="Mart/Ex-Serviceman ward">Mart/Ex-Serviceman ward</option>
              <option value="minor">minor</option>
              <option value="Muslim Minority">Muslim Minority</option>
              <option value="OBC">OBC</option>
              <option value="Other">Other</option>
              <option value="Physical Handicap">Physical Handicap</option>
              <option value="SC">SC</option>
              <option value="ST">ST</option>
              <option value="Widow">Widow</option>
            </select>
            <label className="formlabel">Caste</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='religion'>
              <option >Select </option>
              <option value="Bodh">Bodh</option>
              <option value="Christian">Christian</option>
              <option value="Hindu">Hindu</option>
              <option value="Jain">Jain</option>
              <option value="Jain">Jain</option>
              <option value="Sikh">Sikh</option>
            </select>
            <label className="formlabel">Religion</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="date" id='birthday' />
            <label className="formlabel">Birthday</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='admissioncategory'>
              <option >Select </option>
              <option value="Bodh">Bodh</option>
              <option value="Christian">Christian</option>
              <option value="Hindu">Hindu</option>
              <option value="Jain">Jain</option>
              <option value="Jain">Jain</option>
              <option value="Sikh">Sikh</option>
            </select>
            <label className="formlabel">Admission Category</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="date" id='admissiondate' />
            <label className="formlabel">Admission Date</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="date" id='enrolldate' />
            <label className="formlabel">Enroll Date</label>
          </div>
        </div>
      </div>
      <hr />
      <h6>Details</h6>
      <div className='row mt-4'>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='phone' />
            <label className="formlabel">Phone Number</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='email' />
            <label className="formlabel">Email</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='pan' />
            <label className="formlabel">PAN Number</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='parentsphone' />
            <label className="formlabel">Parents Phone Number</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='aadhar' />
            <label className="formlabel">Aadhar</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='placeofbirth' />
            <label className="formlabel">Place Of Birth</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <select className="kindname" name='kind' id='nationality'>
              <option>Select</option>
              <option value="India">India</option>
            </select>
            <label className="formlabel">Nationality</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='studentaliasname' />
            <label className="formlabel">Student Name Alias</label>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="material-textfield mt-4">
            <textarea className="pname" name='pname' type="text" id='address' />
            <label className="formlabel">Address</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='city' />
            <label className="formlabel">City</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='state' />
            <label className="formlabel">State</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='country' />
            <label className="formlabel">Country</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="material-textfield mt-4">
            <input className="pname" name='pname' type="text" id='pincode' />
            <label className="formlabel">Pincode</label>
          </div>
        </div>
      </div>
      <button className='btn btn-primary mt-5' onClick={savestudent}>Save</button>
    </Box>
  )
}

export default AddStudent