import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyA_PLG6X4zhpajdVURf2pqcmKVpyLDCgy0",
  authDomain: "university-project-6a27d.firebaseapp.com",
  projectId: "university-project-6a27d",
  storageBucket: "university-project-6a27d.appspot.com",
  messagingSenderId: "334934120248",
  appId: "1:334934120248:web:f4f87ef10437cc616653cd"
};
firebase.initializeApp(firebaseConfig);
export { firebase };