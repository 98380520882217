import React from 'react'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import granite4 from "../../assets/img/granite4.jpg"
import { AiOutlineSearch, AiFillInfoCircle, AiOutlineFileText, } from 'react-icons/ai'
import { RiDeleteBin6Line, RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import { MdEdit } from "react-icons/md"
import { IoMdShareAlt } from "react-icons/io"
import { BiBarChartAlt2 } from "react-icons/bi"
import { Link } from 'react-router-dom';
import "../../assets/css/addproduct.css"
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import axios from "axios"

const Addhostel = () => {

    const addhostel = async () => {
        var blocknumber = document.getElementById("blockno").value
        var capacity = document.getElementById("capacity").value
        var description = document.getElementById("description").value
        var gender = document.getElementById("gender").value
        var totalroom = document.getElementById("totalroom").value
        var totalemployee = document.getElementById("totalemployee").value
        var warden = document.getElementById("warden").value

        var data = {
            blocknumber: blocknumber,
            totalcapacity: capacity,
            description: description,
            gender: gender,
            totalroom: totalroom,
            totalempolyee: totalemployee,
            wardenincharge: warden

        }
        var addhostel = await axios.post(`${process.env.REACT_APP_SERVER}/hostel/create`, data).then((res) => { return res.data })
        if (addhostel !== null) {
            alert("Hostel Added Successfully")
            window.location.replace("/hostel")
        }
    }
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >

            <div className="row">
                <div className="col-md-12" id="productdiv">
                    <h6 className="divtitle">Add Block</h6>
                    <div className='row'>
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="blockno"
                                />
                                <label className="formlabel">Block Number</label>
                            </div>
                        </div>
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="capacity"
                                />
                                <label className="formlabel">Total Capacity</label>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                    <div className="row">
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="description"
                                />
                                <label className="formlabel">Description</label>
                            </div>
                        </div>
                        <div className='col-md-4 box'>
                            <div className="material-textfield mt-4">
                                <select className="kindname" name="kind" id="gender">
                                    <option>Select </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Thirty">Thirty</option>
                                </select>
                                <label className="formlabel">Gender</label>
                            </div>

                        </div>
                        <div className='col-md-4'></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 box1'>
                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="totalroom"
                                />
                                <label className="formlabel">Total Room</label>
                            </div>
                        </div>
                        <div className='col-md-3 box'>

                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="totalemployee"
                                />
                                <label className="formlabel">Total Employee</label>
                            </div>
                        </div>
                        <div className='col-md-3 box'>

                            <div className="material-textfield mt-4">
                                <input
                                    type="text"
                                    variant="outlined"
                                    className="pname"
                                    name="pname"
                                    id="warden"
                                />
                                <label className="formlabel">Warden In Charge</label>
                            </div>
                        </div>
                        <div className='col-md-3'></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 box'>
                            <Button variant="contained" color="primary" onClick={addhostel}>
                                Add Block
                            </Button>
                        </div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                    </div>


                </div>
            </div>

        </Box>
    )
}

export default Addhostel