import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import { AiOutlineSearch } from 'react-icons/ai'
import { RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import { Link } from 'react-router-dom';
import "../../assets/css/addproduct.css"
import axios from "axios"

const Department = () => {
    const [departmentdata, setdepartmentdata] = useState([])
    const [divisiondata, setdivisiondata] = useState([])
    const addemployee = async () => {
        var departmentname = document.getElementById("departmentname").value
        var division = document.getElementById("division").value
        var data = {
            departmentname: departmentname,
            division: division,
        }
        var createcourse = await axios.post(`${process.env.REACT_APP_SERVER}/division/create/`, data).then((res) => { return res.data })
        if (createcourse !== null) {
            alert("Department Added...")
            window.location.reload()
        }
    }
    useEffect(() => {
        getcoursedata()
    }, [])
    const getcoursedata = async () => {
        var alldepartment = await axios.get(`${process.env.REACT_APP_SERVER}/department/`).then((res) => { return res.data })
        var alldivision = await axios.get(`${process.env.REACT_APP_SERVER}/division/`).then((res) => { return res.data })
        setdepartmentdata(alldepartment)
        setdivisiondata(alldivision)
    }
    const searchname = (e) => {
        if (e.target.value.length !== 0) {
            var data = []
            for (var i = 0; i < divisiondata.length; i++) {
                if (divisiondata[i].departmentname.toLowerCase().includes(e.target.value.toLowerCase())) {
                    data.push(divisiondata[i])
                }
            }
            setdivisiondata(data)
        } else {
            getcoursedata()
        }
    }
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >
            <div className="row">
                <div className="col-sm-2">
                    <h3>Management</h3>
                </div>
                <div className='col-sm-2'></div>
                <div className="col-sm-4 searchinput">
                    <div className="form-group has-search">
                        <AiOutlineSearch className="fa fa-search form-control-feedback" />
                        <input type="text" className="form-control" placeholder="Search By Department" onChange={searchname} />
                    </div>
                </div>
                <div className="col-sm-2" style={{ marginLeft: "10px" }}>
                    <button variant="contained" id="addbtn" color="primary" size="small" data-bs-toggle="modal" data-bs-target="#exampleModal" startIcon={<AddIcon />}><span className="linktag">Add New</span> </button>
                </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Cousre</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>

                                <div className='col-md-6'>
                                    <div className="material-textfield mt-4">
                                        <select className="kindname" name="kind" id="departmentname">
                                            <option value="null">Select</option>
                                            {departmentdata.length !== 0 ? departmentdata.map((data, index) => (
                                                <option key={index} value={data.departmentname}>{data.departmentname}</option>
                                            )) : null}

                                        </select>
                                        <label className="formlabel">Department</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="material-textfield mt-4">
                                        <input
                                            className="pname"
                                            name="pname"
                                            type="text"
                                            id="division"
                                        />
                                        <label className="formlabel">Division</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={addemployee}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-5">
                    <div className='row'>
                        <div className='col-md-12'>
                            <p className='reg__heading'><Link className='taglink' to="/management">Course List</Link></p>
                            <p className='reg__heading'><Link className='taglink' to="/department">Department List</Link></p>
                            <p className='reg__heading'><Link className='taglink' to="/division">Division List</Link></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10" id="productdiv">
                    <div className="row ">
                        <div className='col-md-10'>
                            <div className='emp__subheading'>Division</div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <span className='righticon' >
                                    <RiFileExcelLine />
                                </span>
                                <span className="righticon">
                                    <BsPrinter />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <table class="table table-borderless">
                                <thead className='table__header'>
                                    <tr>
                                        <th>#</th>
                                        <th>Department</th>
                                        <th>Division</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {divisiondata.length !== 0 ? divisiondata.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.departmentname}</td>
                                            <td>{data.division}</td>
                                        </tr>
                                    )) : null}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default Department