import React from "react";
import GalleryMain from "../../Component/Gallery/GalleryMain";

const Gallery = () => {
  return (
    <div>
      <GalleryMain />
    </div>
  );
};

export default Gallery;
