import React from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "../../assets/css/addproduct.css";
import Avatar from "@material-ui/core/Avatar";
import demo from "../../assets/img/demoimg.webp";
import EmpolyeeList from "./EmpolyeeList";
import classes from "../../assets/css/Employee.module.css";
import { firebase } from "../../database/firebase";
import axios from "axios"
import { useState } from "react";


const AddEmployee = () => {
  const [imageurl1, setimageurl1] = useState(null);
  const [imageurl2, setimageurl2] = useState(null);

  const getimgaeurl = async () => {
    var file = document.getElementById("image").files;
    let file11 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("profile/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl = await file11;
    setimageurl1(imgurl)
  }

  const getimgaeurl2 = async () => {
    var file2 = document.getElementById("image2").files;
    let file22 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("profile/" + file2[0].name);
      storageRef.put(file2[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl2 = await file22
    setimageurl2(imgurl2)
  }

  const addemployee = async () => {

    var campus = document.getElementById("campus").value
    var institute = document.getElementById("institute").value
    var resumeNo = document.getElementById("resumeNo").value
    var employeecode = document.getElementById("employeeCode").value
    var salutation = document.getElementById("salutation").value
    var employeeName = document.getElementById("employeeName").value
    var dob = document.getElementById("dob").value
    var marriageDate = document.getElementById("marriageDate").value
    var employeeGroup = document.getElementById("employeeGroup").value
    var gender = document.getElementById("gender").value
    var fatherrName = document.getElementById("fatherrName").value
    var motherName = document.getElementById("motherName").value
    var martialStatus = document.getElementById("martialStatus").value
    var sign = document.getElementById("sign").value
    var workingHours = document.getElementById("workingHours").value
    var code = document.getElementById("code").value
    var religion = document.getElementById("religion").value
    var blooadgroup = document.getElementById("blooadgroup").value
    var nationality = document.getElementById("nationality").value
    var caste = document.getElementById("caste").value
    var appoinmentType = document.getElementById("appoinmentType").value
    var from = document.getElementById("from").value
    var to = document.getElementById("to").value
    var jobStatus = document.getElementById("jobStatus").value
    var vechileNo = document.getElementById("vechileNo").value
    var license = document.getElementById("license").value
    var expiryDate = document.getElementById("expiryDate").value
    var address = document.getElementById("address").value
    var country = document.getElementById("country").value
    var city = document.getElementById("city").value
    var state = document.getElementById("state").value
    var pincode = document.getElementById("pincode").value
    var data = {
      campus: campus,
      institute: institute,
      resumeNo: resumeNo,
      empolyeecode: employeecode,
      salutation: salutation,
      empolyeename: employeeName,
      dateofbirth: dob,
      anniversary: marriageDate,
      empolyeegroup: employeeGroup,
      gender: gender,
      fathername: fatherrName,
      mothername: motherName,
      martialStatus: martialStatus,
      bodysign: sign,
      workinghours: workingHours,
      aictecode: code,
      religion: religion,
      bloodgroup: blooadgroup,
      nationality: nationality,
      caste: caste,
      appoinmentType: appoinmentType,
      from: from,
      to: to,
      jobstatus: jobStatus,
      vechileno: vechileNo,
      drivinglicense: license,
      licenseexpireddate: expiryDate,
      address: address,
      country: country,
      city: city,
      state: state,
      pincode: pincode,
      profile: imageurl1,
      signature: imageurl2,

    }
    console.log(data)
    var response = await axios.post(`${process.env.REACT_APP_SERVER}/empolyee`, data).then((res) => { return res.data })
    if (response !== null) {
      alert("Employee Successfully added...")
      window.location.replace("/employee")
    }
  }
  return (
    <div className={classes.AddEmployeeFormContainer}>
      <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1}>
        <div className={classes.AddEmployeeFormContainerHead}>
          <h6 className="divtitle">Add Employee</h6>
        </div>
        <div className="row">
          <div className="col-md-2">
            {imageurl1 === null ? <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> : <Avatar alt="Remy Sharp" src={imageurl1} />}
            <div className="material-textfield mt-4">
              <input
                placeholder=""
                className="pname"
                name="pname"
                type="file"
                id="image"
                onChange={getimgaeurl}
              />
              <label className="formlabel">Profile Picture</label>
            </div>
          </div>
          <div className="col-md-2">
            {imageurl2 === null ? <img src={demo} alt="" srcset="" style={{ width: "48px" }} /> : <Avatar alt="Remy Sharp" src={imageurl2} />}
            <div className="material-textfield mt-4">
              <input
                placeholder=""
                className="pname"
                name="pname"
                type="file"
                id="image2"
                onChange={getimgaeurl2}
              />
              <label className="formlabel">Signature</label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="campus">
                <option>Select </option>
                <option value="AAYOJAN SCHOOL OF ARCHITECTURE">
                  AAYOJAN SCHOOL OF ARCHITECTURE
                </option>
              </select>
              <label className="formlabel">Campus</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="institute">
                <option>Select </option>
                <option value="AAYOJAN SCHOOL OF ARCHITECTURE">
                  AAYOJAN SCHOOL OF ARCHITECTURE
                </option>
              </select>
              <label className="formlabel">Institute</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="text" id="resumeNo" />
              <label className="formlabel">Resume No</label>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="text"
                id="employeeCode"
              />
              <label className="formlabel">Employee Code</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="salutation">
                <option>Select </option>
                <option value="Dr">Dr </option>
                <option value="Mr">Mr </option>
                <option value="Mrs">Mrs </option>
                <option value="Ms">Ms </option>
              </select>
              <label className="formlabel">Salutation</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="text"
                id="employeeName"
              />
              <label className="formlabel">Employee Name</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="date" id="dob" />
              <label className="formlabel">Date of Birth</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="date"
                id="marriageDate"
              />
              <label className="formlabel">Anniversary Date</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="employeeGroup">
                <option>Select </option>
                <option value='ADMINISTRATION'>ADMINISTRATION </option>
                <option value='NON-TEACHING'>NON-TEACHING </option>
                <option value='TEACHING'>TEACHING </option>
              </select>
              <label className="formlabel">Employee Group</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="gender">
                <option value="">Gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="T">Transgender</option>
                <option value="O">Other</option>
              </select>
              <label className="formlabel">Gender</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="text"
                id="fatherrName"
              />
              <label className="formlabel">Father Name</label>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="text"
                id="motherName"
              />
              <label className="formlabel">Mother Name</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="martialStatus">
                <option>Select </option>
                <option value="Single">Single </option>
                <option value="Married">Married </option>
                <option value="Separated">Separated </option>
                <option value="Widowed">Widowed </option>
                <option value="Others">Others </option>
              </select>
              <label className="formlabel">Martial status</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="text" id="sign" />
              <label className="formlabel">Body Sign</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="number"
                id="workingHours"
              />
              <label className="formlabel">Working Hours</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="text" id="code" />
              <label className="formlabel">AICTE CODE</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="religion">
                <option>Select </option>
                <option value="Bodh">Bodh</option>
                <option value="Christian">Christian</option>
                <option value="Hindu">Hindu</option>
                <option value="Jain">Jain</option>
                <option value="Jain">Jain</option>
                <option value="Sikh">Sikh</option>
              </select>
              <label className="formlabel">Religion</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="blooadgroup">
                <option value="">Select Blood Group</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </select>
              <label className="formlabel">Blood Group</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="nationality">
                <option>Select </option>
                <option value="India">India </option>
              </select>
              <label className="formlabel">Nationality</label>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="caste">
                <option>Select </option>
                <option value="DEC ST EMPL WARD">DEC ST EMPL WARD</option>
                <option value="General">General</option>
                <option value="GENERAL (MINORITY)">GENERAL (MINORITY)</option>
                <option value="Jain Minority">Jain Minority</option>
                <option value="Mart/Ex-Serviceman ward">
                  Mart/Ex-Serviceman ward
                </option>
                <option value="minor">minor</option>
                <option value="Muslim Minority">Muslim Minority</option>
                <option value="OBC">OBC</option>
                <option value="Other">Other</option>
                <option value="Physical Handicap">Physical Handicap</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
                <option value="Widow">Widow</option>
              </select>
              <label className="formlabel">Caste</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="appoinmentType">
                <option>Select </option>
                <option value="Permanent">Permanent </option>
                <option value="Regular">Regular </option>
                <option value="Visiting">Visiting </option>
                <option value="Temporary">Temporary </option>
              </select>
              <label className="formlabel">Appoinment Type</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="date" id="from" />
              <label className="formlabel">From</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="date" id="to" />
              <label className="formlabel">To</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <select className="kindname" name="kind" id="jobStatus">
                <option>Select </option>
                <option value="current">Current </option>
                <option value="cancel">cancel </option>
                <option value="Pending">Pending </option>
                <option value="Blacklist">Blacklist </option>
                <option value="Study Level">Study Level </option>
                <option value="Retired">Retired </option>
                <option value="Long Leave">Long Leave </option>
              </select>
              <label className="formlabel">Job Status</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="text"
                id="vechileNo"
              />
              <label className="formlabel">Vechile Number</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input className="pname" name="pname" type="text" id="license" />
              <label className="formlabel">Driving License</label>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="material-textfield mt-4">
              <input
                className="pname"
                name="pname"
                type="date"
                id="expiryDate"
              />
              <label className="formlabel">Driving License Expiry date</label>
            </div>
          </div>
        </div>
        <hr />
        <div className={classes.addEmployeeFormUl}>
          <ul>
            <li className={classes.activeLi}>Address</li>
            <li>Office</li>
            <li>Role</li>
            <li>Skills</li>
            <li>Documents</li>
            <li>Qualification</li>
            <li>Experience</li>
            <li>Achievement</li>
            <li>Ward</li>
            <li>Co-Curricular</li>
            <li>Reference</li>
            <li>Research & Guidance</li>
          </ul>
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="material-textfield mt-4">
                <textarea className="pname" name='pname' type="text" id='address' />
                <label className="formlabel">Address</label>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="material-textfield mt-4">
                <input className="pname" name='pname' type="text" id='city' />
                <label className="formlabel">City</label>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="material-textfield mt-4">
                <input className="pname" name='pname' type="text" id='state' />
                <label className="formlabel">State</label>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="material-textfield mt-4">
                <input className="pname" name='pname' type="text" id='country' />
                <label className="formlabel">Country</label>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="material-textfield mt-4">
                <input className="pname" name='pname' type="text" id='pincode' />
                <label className="formlabel">Pincode</label>
              </div>
            </div>
          </div>
        </div>

        <button className="btn btn-primary" onClick={addemployee}>Save</button>
      </Box>
    </div>
  );
};

export default AddEmployee;
