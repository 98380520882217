import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import { AiOutlineSearch } from 'react-icons/ai'
import { RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import { Link } from 'react-router-dom';
import "../../assets/css/addproduct.css"
import axios from "axios"

const Management = () => {
    const [coursedata, setcoursedata] = useState([])
    const addemployee = async () => {
        var coursename = document.getElementById("coursename").value
        var courselevel = document.getElementById("courselevel").value
        var coursetype = document.getElementById("coursetype").value
        var courseperiod = document.getElementById("courseperiod").value
        var data = {
            coursename: coursename,
            courselevel: courselevel,
            coursetype: coursetype,
            courseperiod: courseperiod
        }
        var createcourse = await axios.post(`${process.env.REACT_APP_SERVER}/course/create/`, data).then((res) => { return res.data })
        if (createcourse !== null) {
            alert("Course Added...")
            window.location.reload()
        }
    }
    useEffect(() => {
        getcoursedata()
    }, [])
    const getcoursedata = async () => {
        var allcourse = await axios.get(`${process.env.REACT_APP_SERVER}/course/`).then((res) => { return res.data })
        setcoursedata(allcourse)
    }
    const searchname = (e) => {
        if (e.target.value.length !== 0) {
            var data = []
            for (var i = 0; i < coursedata.length; i++) {
                if (coursedata[i].coursename.toLowerCase().includes(e.target.value.toLowerCase())) {
                    data.push(coursedata[i])
                }
            }
            setcoursedata(data)
        } else {
            getcoursedata()
        }
    }
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >
            <div className="row">
                <div className="col-sm-2">
                    <h3>Management</h3>
                </div>
                <div className='col-sm-2'></div>
                <div className="col-sm-4 searchinput">
                    <div className="form-group has-search">
                        <AiOutlineSearch className="fa fa-search form-control-feedback" />
                        <input type="text" className="form-control" placeholder="Search By Course Name" onChange={searchname} />
                    </div>
                </div>
                <div className="col-sm-2" style={{ marginLeft: "10px" }}>
                    <button variant="contained" id="addbtn" color="primary" size="small" data-bs-toggle="modal" data-bs-target="#exampleModal" startIcon={<AddIcon />}><span className="linktag">Add New</span> </button>
                </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Cousre</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="material-textfield mt-4">
                                        <input
                                            className="pname"
                                            name="pname"
                                            type="text"
                                            id="coursename"
                                        />
                                        <label className="formlabel">Course Name</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="material-textfield mt-4">
                                        <select className="kindname" name="kind" id="courselevel">
                                            <option value="null">Select</option>
                                            <option value="Bachelor Degree">Bachelor Degree</option>
                                            <option value="Diploma degree">Diploma degree</option>
                                            <option value="M.Phil Programme">M.Phil Programme</option>
                                            <option value="Master Degree">Master Degree</option>
                                            <option value="P.G. DIPLOMA">P.G. DIPLOMA</option>
                                            <option value="Phd Programme">Phd Programme</option>
                                        </select>
                                        <label className="formlabel">Cousre Level</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="material-textfield mt-4">
                                        <select className="kindname" name="kind" id="coursetype">
                                            <option value="null">Select</option>
                                            <option value="Certification">Certification</option>
                                            <option value="UG">UG</option>
                                            <option value="PG">PG</option>
                                            <option value="Diploma">Diploma</option>
                                            <option value="Doctorate">Doctorate</option>
                                            <option value="K-12 Schooling">K-12 Schooling</option>
                                        </select>
                                        <label className="formlabel">Cousre Type</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="material-textfield mt-4">
                                        <select className="kindname" name="kind" id="courseperiod">
                                            <option value="null">Select</option>
                                            <option value="One Month">One Month</option>
                                            <option value="Two Month">Two Month</option>
                                            <option value="Three Month">Three Month</option>
                                            <option value="Four Month">Four Month</option>
                                            <option value="Half Yearly">Half Yearly</option>
                                            <option value="Yearly">Yearly</option>
                                        </select>
                                        <label className="formlabel">Cousre Period</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={addemployee}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-5">
                    <div className='row'>
                        <div className='col-md-12'>
                            <p className='reg__heading'><Link className='taglink' to="/management">Course List</Link></p>
                            <p className='reg__heading'><Link className='taglink' to="/department">Department List</Link></p>
                            <p className='reg__heading'><Link className='taglink' to="/division">Division List</Link></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-10" id="productdiv">
                    <div className="row ">
                        <div className='col-md-10'>
                            <div className='emp__subheading'>Course</div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <span className='righticon' >
                                    <RiFileExcelLine />
                                </span>
                                <span className="righticon">
                                    <BsPrinter />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <table class="table table-borderless">
                                <thead className='table__header'>
                                    <tr>
                                        <th>#</th>
                                        <th>Course Name</th>
                                        <th>Course Level</th>
                                        <th>Course Type</th>
                                        <th>Course Period</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coursedata.length !== 0 ? coursedata.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.coursename}</td>
                                            <td>{data.courselevel}</td>
                                            <td>{data.coursetype}</td>
                                            <td>{data.courseperiod}</td>
                                        </tr>
                                    )) : null}


                                </tbody>
                            </table>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default Management