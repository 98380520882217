import React from "react";
import classes from "../../assets/css/Gallery.module.css";
const GalleryImgCard = (props) => {
    console.log(props)
    return (
        <div className={classes.ImgCard}>
            <div>
                <video style={{height:"200px",width:"200px"}} controls>
                    <source src={props.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div>
                <p>{props.title}</p>
            </div>
        </div>
    );
};

export default GalleryImgCard;
