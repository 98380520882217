import React from "react";
import DefaultLogin from "../Component/Login/DefaultLogin";
import styles from "../assets/css/Login.module.css";
import PasswordChange from "../Component/Login/ForgetPassword";
import { useSelector } from "react-redux";
import NewRegister from "../Component/Login/NewRegister";
const Login = () => {
  const forgetPassword = useSelector((store) => store.forgetPassword);
  const isRegister = useSelector((store) => store.register);
  return (
    <div className={styles.loginShowCase}>
      {!forgetPassword && isRegister && <DefaultLogin />}
      {forgetPassword && <PasswordChange />}
      {!isRegister && <NewRegister />}
    </div>
  );
};

export default Login;
