import React from "react";
import classes from "../../assets/css/Gallery.module.css";
const GalleryImgCard = (props) => {
  return (
    <div className={classes.ImgCard}>
      <div>
        <img src={props.img} alt="img" style={{height:"200px",width:"200px"}}/>
      </div>
      <div>
        <p>{props.title}</p>
      </div>
    </div>
  );
};

export default GalleryImgCard;
