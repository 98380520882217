import React, { useState, useEffect } from "react";
import { Button, InputBase } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import styles from "../../assets/css/defaultLogin.module.css";
import "../../assets/css/dymmy.css";
import { useDispatch } from "react-redux";
import { loginAction } from "../../Store/Store";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axios from "axios";
import logo from "../../assets/img/university logo.png"
const DefaultLogin = () => {
  const [disabled, setdisabled] = useState(false);
  const dispatch = useDispatch();
  const forgotPasswordHandler = () => {
    dispatch(loginAction.changePassword());
  };
  const newRegisterHandler = () => {
    dispatch(loginAction.newRegister());
  };
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  const loginHandler = async () => {
    setdisabled(true)
    var email = document.getElementById("email").value
    var password = document.getElementById("password").value
    var data = {
      password: password,
      email: email
    }
    var checkuser = await axios.post(`${process.env.REACT_APP_SERVER}/user/login`, data).then((res) => { return res.data })
    if (checkuser.token !== undefined) {
      sessionStorage.setItem("userid", checkuser.user._id)
      alert("Welcome..")
      window.location.replace("/")
    } else {
      alert(checkuser)
      setdisabled(false)
    }

  };
  useEffect(() => {
    var userid = sessionStorage.getItem("userid")
    if (userid !== null) {
      dispatch(loginAction.loginhandler());
      // window.location.replace("/student")
    }
  })
  return (
    <div className={styles.card}>
      <div className={styles.containers}>
        <center className={styles.loginHead}>
          <img src={logo} alt=""/>
        </center>
        <div className={styles.actionContainer}>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <PersonIcon />
            </div>
            <InputBase
              className={styles.input}
              id="email"
              placeholder="Email"
            />
          </div>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <LockIcon />
            </div>
            <Input
              id="password"
              className={styles.input}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handlePassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          {disabled === true ? (
            <Button
              variant="contained"
              color="primary"
              id="loginbtn"
              className={styles.bt}
              disabled
              onClick={loginHandler}
            >
              Sign in
            </Button>
          ) : (
            <Button
            className={styles.bt}
              variant="contained"
              color="primary"
              id="loginbtn"
              onClick={loginHandler}
            >
              Sign in
            </Button>
          )}
        </div>

        <div className={styles.forgetPassword}>
          <p onClick={forgotPasswordHandler}>
            <span>Forget Password ?</span>
          </p>
          <p onClick={newRegisterHandler}>
            Don't have a account ?<span> Register</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DefaultLogin;
