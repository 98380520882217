import React, { useEffect, useState } from 'react'
import { Box } from "@material-ui/core";
import classes from "../../assets/css/dashBoard.module.css"
import EmployeeAna from './EmployeeAna';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import axios from 'axios';
import moment from 'moment';

const Dashboard = () => {
  const [images, setimages] = useState([])
  const [totaluser, settotaluser] = useState([])
  const [totalemployee, settotalemployee] = useState([])

  const [presentuser, setpresentuser] = useState([])
  const [absentuser, setabsentuser] = useState([])
  const [otheruser, setotheruser] = useState(0)

  const [presentemployee, setpresentemployee] = useState([])
  const [absentemployee, setabsentemployee] = useState([])
  const [otheremployee, setotheremployee] = useState(0)
  useEffect(() => {
    getgallerydata()
  }, [])
  const getgallerydata = async () => {
    var today = moment().format("DD-MM-YYYY")
    var images = await axios.get(`${process.env.REACT_APP_SERVER}/image/`).then((res) => { return res.data })
    if (images.length !== 0) {
      var myBest = images.slice(0, 4);
      setimages(myBest)
    }
    var alluserdata = await axios.get(`${process.env.REACT_APP_SERVER}/student`).then((res) => { return res.data })
    settotaluser(alluserdata)
    var data = await axios.get(`${process.env.REACT_APP_SERVER}/empolyee`).then((res) => { return res.data })
    settotalemployee(data)
    var upsentuser = await alluserdata.filter((data) => { return data.attendance === "Absent" && data.date === today })
    var presentuser = await alluserdata.filter((data) => { return data.attendance === "Present" && data.date === today })
    setpresentuser(presentuser)
    setabsentuser(upsentuser)
    var otherusers = Number(alluserdata.length) - (Number(presentuser.length) + Number(upsentuser.length))
    setotheruser(otherusers)

    var upsentemployee = await data.filter((datanew) => { return datanew.attendance === "Absent" && datanew.date === today })
    var presentemployee = await data.filter((datanew) => { return datanew.attendance === "Present" && datanew.date === today })
    setpresentemployee(presentemployee)
    setabsentemployee(upsentemployee)
    var otheremployee = Number(data.length) - (Number(presentemployee.length) + Number(upsentemployee.length))
    setotheremployee(otheremployee)
  }

  return (
    <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1}>
      <h2 className={classes.dashboardHead}>
        Dashboard
      </h2>
      <div className={classes.employeeAnalytics}>
        <h4>STUDENT ATTENDANCE STATUS</h4>
        <div className={classes.employeeAnalyticsBox}>
          <EmployeeAna className={classes.EmployeeAnaContainerB} totalemployee={totaluser.length} employeelist='Total Employee' />
          <EmployeeAna className={classes.EmployeeAnaContainerG} totalemployee={presentuser.length} employeelist='Total Present' />
          <EmployeeAna className={classes.EmployeeAnaContainerY} totalemployee={absentuser.length} employeelist='Total Absent' />
          <EmployeeAna className={classes.EmployeeAnaContainerR} totalemployee={otheruser} employeelist='Late Arrival' />
        </div>
      </div>
      <div className={classes.employeeAnalytics}>
        <h4>EMPLOYEE ATTENDANCE STATUS</h4>
        <div className={classes.employeeAnalyticsBox}>
          <EmployeeAna className={classes.EmployeeAnaContainerB} totalemployee={totalemployee.length} employeelist='Total Employee' />
          <EmployeeAna className={classes.EmployeeAnaContainerG} totalemployee={presentemployee.length} employeelist='Total Present' />
          <EmployeeAna className={classes.EmployeeAnaContainerY} totalemployee={absentemployee.length} employeelist='Total Absent' />
          <EmployeeAna className={classes.EmployeeAnaContainerR} totalemployee={otheremployee} employeelist='Late Arrival' />
        </div>
      </div>
      <div className={classes.dashboardContent}>
        <div>
          <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" />
        </div>
        <div></div>
        <div className={classes.dashboardGallery}>
          <h3>Gallery</h3>
          <div className={classes.dashboardGalleryImg}>
            {images.length !== 0 ? images.map((data, index) => (
              <img src={data.image} alt="gall" style={{ height: "190px", width: "240px" }} />
            )) :
              <div className='text-center mt-5'>
                <p>No Gallery Found</p>
              </div>}
          </div>
        </div>
      </div>

    </Box>
  )
}

export default Dashboard