import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import "../../assets/css/addproduct.css"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios'
import moment from 'moment'

const Attendance = () => {
    const [alluserlist, setalluserlist] = useState([]);
    const [userlist, setuserlist] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [coursedata, setcoursedata] = useState([])
    const [departmentdata, setdepartmentdata] = useState([])
    const [divisiondata, setdivisiondata] = useState([])
    useEffect(() => {
        getstudentdata()
    }, [])
    const getstudentdata = async () => {
        var alluserdata = await axios.get(`${process.env.REACT_APP_SERVER}/student`).then((res) => { return res.data })
        setuserlist(alluserdata)
        setalluserlist(alluserdata)
    }
    const getattendance = (e) => {
        const { name, value } = e.target;
        setFormValues([...formValues, [name, value]]);
    }
    const applyattendance = async () => {
        for (var i = 0; i < formValues.length; i++) {
            var newdata = {
                attendance: formValues[i][1],
                date: moment().format("DD-MM-YYYY")
            }
            await axios.post(`${process.env.REACT_APP_SERVER}/student/update/${formValues[i][0]}`, newdata).then((res) => { return res.data })
        }
        alert("Attendance Added...")
        setTimeout(() => {
            window.location.replace("/")
        }, 3000);

    }
    useEffect(() => {
        getdata()
    }, [])
    const getdata = async () => {
        var allcourse = await axios.get(`${process.env.REACT_APP_SERVER}/course/`).then((res) => { return res.data })
        setcoursedata(allcourse)
    }
    const getcourse = async (e) => {
        var filteruser = alluserlist.filter((data) => { return data.course === e.target.value })
        setuserlist(filteruser)
        var alldepartment = await axios.get(`${process.env.REACT_APP_SERVER}/department/`).then((res) => { return res.data })
        var mydepartment = await alldepartment.filter((data) => { return data.course === e.target.value })
        setdepartmentdata(mydepartment)
    }
    const getdepatment = async (e) => {
        var filteruser = userlist.filter((data) => { return data.department === e.target.value })
        setuserlist(filteruser)
        var alldivision = await axios.get(`${process.env.REACT_APP_SERVER}/division/`).then((res) => { return res.data })
        var mydepartment = await alldivision.filter((data) => { return data.departmentname === e.target.value })
        setdivisiondata(mydepartment)
    }
    const getdivision = (e) => {
        var filteruser = userlist.filter((data) => { return data.division === e.target.value })
        setuserlist(filteruser)
    }
    const clearall = () => {
        setuserlist(alluserlist)
        setdepartmentdata([])
        setdivisiondata([])
        setcoursedata([])
        getdata()
    }
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >
            <div className="row">
                <div className="col-sm-2">
                    <h3>Attendance</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-5">


                </div>
                <div className="col-md-10" id="productdiv">
                    <div className="row ">
                        <div className='col-md-10'>
                            <div className='emp__subheading'>Student Attendance - {moment().format("MMM Do YYYY")}</div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <span className='righticon' >
                                    <RiFileExcelLine />
                                </span>
                                <span className="righticon">
                                    <BsPrinter />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 box'>
                            <div className="material-textfield mt-4">
                                <select className="kindname" name='kind' id='institute' onChange={getcourse}>
                                    <option >Select </option>
                                    {coursedata.length !== 0 ? coursedata.map((data) => (
                                        <option value={data.coursename}>{data.coursename} </option>
                                    )) : null}
                                </select>
                                <label className="formlabel">Course</label>
                            </div>
                        </div>

                        <div className='col-md-3 box'>
                            <div className="material-textfield mt-4">
                                <select className="kindname" name='kind' id='institute' onChange={getdepatment}>
                                    <option >Select </option>
                                    {departmentdata.length !== 0 ? departmentdata.map((data) => (
                                        <option value={data.departmentname}>{data.departmentname} </option>
                                    )) : null}
                                </select>
                                <label className="formlabel">Department</label>
                            </div>
                        </div>
                        <div className='col-md-3 box'>

                            <div className="material-textfield mt-4">
                                <select className="kindname" name='kind' id='institute' onChange={getdivision}>
                                    <option >Select </option>
                                    {divisiondata.length !== 0 ? divisiondata.map((data) => (
                                        <option value={data.division}>{data.division} </option>
                                    )) : null}
                                </select>
                                <label className="formlabel">Department</label>
                            </div>
                        </div>
                        <div className='col-md-3 box mt-5'>
                            <button className="btn btn-info" onClick={clearall}>Clear All</button>
                        </div>


                    </div>

                    <div className='row'>
                        <div className='col-md-10 table__top'>
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Student Name</th>
                                        <th>Scholar No</th>
                                        <th>Enrollment No</th>
                                        <th>Attendance</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {userlist.length !== 0 ? userlist.map((data, index) => (
                                        <tr key={index}>
                                            <td> {index + 1}</td>
                                            <td>{data.firstname} {data.middlename} {data.lastname}</td>
                                            <td>{data.scholarno}</td>
                                            <td>{data.enrollno}</td>
                                            <td>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <input type="radio" name={data._id} value="Present" onChange={getattendance} />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <p>Present</p>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <input type="radio" name={data._id} value="Absent" onChange={getattendance} />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <p>Absent</p>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )) : null}


                                </tbody>
                            </table>
                        </div>
                        <div className='col-md-2'></div>
                    </div>

                    <div className='row'>
                        <div className="col-md-10">
                            <div className='row'>
                                <div className='col-md-10'></div>
                                <div className='col-md-2 box'>
                                    <Button variant="contained" color="primary" onClick={applyattendance}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>

                    </div>


                </div>
            </div>

        </Box>
    )
}

export default Attendance