import React, { useState } from "react";
import { Button, InputBase } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import "../../assets/css/dymmy.css";
import styles from "../../assets/css/NewRegister.module.css";
// import { useDispatch } from "react-redux";
// import { loginAction } from "../../Store/Store";
import axios from 'axios'
import logo from "../../assets/img/university logo.png"


const NewRegister = () => {
  // const dispatch = useDispatch();
  const [disabled, setdisabled] = useState(false);
  const registerSubmitHandler = async () => {
    setdisabled(true)
    var name = document.getElementById("name").value
    var email = document.getElementById("email").value
    var phone = document.getElementById("phone").value
    var password = document.getElementById("password").value
    var data = {
      name: name,
      email: email,
      phone: phone,
      password: password
    }
    console.log(data)
    var createuser = await axios.post(`${process.env.REACT_APP_SERVER}/user`, data).then((res) => { return res.data })
    if(createuser !==null){
      alert("User Created..")
      window.location.reload()
    }
    // dispatch(loginAction.loginhandler());
  };
  const useInput = (
    <div>
      <div className={styles.card}>
        <div className={styles.container}>
          <center className={styles.loginHead}>
           <img src={logo} alt=""/>"
          </center>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <PersonIcon />
            </div>
            <InputBase
              className={styles.input}
              id="name"
              placeholder="Username"
            />
          </div>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <LockIcon />
            </div>
            <InputBase
              className={styles.input}
              type="email"
              id="email"
              placeholder="Email"
            />
          </div>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <LockIcon />
            </div>
            <InputBase
              className={styles.input}
              id="phone"
              type="tel"
              placeholder="Phone"
            />
          </div>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <LockIcon />
            </div>
            <InputBase
              className={styles.input}
              id="password"
              type="password"
              placeholder="Password"
            />
          </div>
          {disabled === true ? (
            <Button
              variant="contained"
              color="primary"
              onClick={registerSubmitHandler}
              disabled
            >
              Register
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={registerSubmitHandler}
            >
              Register
            </Button>
          )}
        </div>
      </div>
    </div>
  );
  return <div>{useInput}</div>;
};

export default NewRegister;
