import React from 'react'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classes from "../../assets/css/dashBoard.module.css"
import PeopleIcon from '@material-ui/icons/People';
import Employee from '../Employee/Employee';

const EmployeeAna = (props) => {
  return (
    <div className={props.className}>
        <div className={classes.EmployeeAnaContent}>
            <div>
                <PeopleIcon className={classes.EmployeeAnaContentIcon}/>
            </div>
            <div>
                <h2>{props.totalemployee}</h2>
                <h6>{props.employeelist}</h6>
            </div>
        </div>
        
        <div className={classes.viewMore}>
            <p>view more</p>
            <NavigateNextIcon className={classes.viewMoreIcon}/>
        </div>
    </div>
  )
}

export default EmployeeAna