import { configureStore, createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLogin: false,
    forgetPassword: false,
    register: true,
  },
  reducers: {
    changePassword(state) {
      state.forgetPassword = true;
    },
    newRegister(state) {
      state.register = false;
    },
    loginhandler(state) {
      state.isLogin = true;
    },
  },
});
export const loginAction = loginSlice.actions;
const Store = configureStore(loginSlice);
export default Store;
