import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { RiFileExcelLine, } from "react-icons/ri"
import { BsPrinter } from "react-icons/bs"
import "../../assets/css/addproduct.css"
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios'
import moment from 'moment'

const Attendance = () => {
    const [userlist, setuserlist] = useState([]);
    const [formValues, setFormValues] = useState([]);
    useEffect(() => {
        getstudentdata()
    }, [])
    const getstudentdata = async () => {
        var alluserdata = await axios.get(`${process.env.REACT_APP_SERVER}/empolyee`).then((res) => { return res.data })
        setuserlist(alluserdata)
    }
    const getattendance = (e) => {
        const { name, value } = e.target;
        setFormValues([...formValues, [name, value]]);
    }
    const applyattendance = async () => {
        for (var i = 0; i < formValues.length; i++) {
            var newdata = {
                attendance: formValues[i][1],
                date: moment().format("DD-MM-YYYY")
            }
            await axios.post(`${process.env.REACT_APP_SERVER}/empolyee/update/${formValues[i][0]}`, newdata).then((res) => { return res.data })
        }
        alert("Attendance Added...")
        setTimeout(() => {
            window.location.replace("/")
        }, 3000);

    }
    console.log(userlist)
    return (
        <Box id="boxdiv" boxShadow={0} bgcolor="background.paper" m={1} p={1} >
            <div className="row">
                <div className="col-sm-2">
                    <h3>Attendance</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-5">


                </div>
                <div className="col-md-10" id="productdiv">
                    <div className="row ">
                        <div className='col-md-10'>
                            <div className='emp__subheading'>Employee Attendance - {moment().format("MMM Do YYYY")}</div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <span className='righticon' >
                                    <RiFileExcelLine />
                                </span>
                                <span className="righticon">
                                    <BsPrinter />
                                </span>
                            </div>
                        </div>


                    </div>
                    {/* <div className='row'>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Name</InputLabel>
                                <Select>
                                    <option value={10}>Ten</option>
                                    <option value={20}>Twenty</option>
                                    <option value={30}>Thirty</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Institute</InputLabel>
                                <Select>
                                    <option value={10}>Ten</option>
                                    <option value={20}>Twenty</option>
                                    <option value={30}>Thirty</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Course</InputLabel>
                                <Select>
                                    <option value={10}>B.ARCH</option>
                                    <option value={20}>B.SC</option>
                                    <option value={30}>MBBS</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Part</InputLabel>
                                <Select>
                                    <option value={10}>sem 1</option>
                                    <option value={20}>sem 2</option>
                                    <option value={30}>sem 2</option>
                                </Select>
                            </FormControl>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Class</InputLabel>
                                <Select>
                                    <option value={10}>Ten</option>
                                    <option value={20}>Twenty</option>
                                    <option value={30}>Thirty</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Subject</InputLabel>
                                <Select>
                                    <option value={10}>Ten</option>
                                    <option value={20}>Twenty</option>
                                    <option value={30}>Thirty</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Batch</InputLabel>
                                <Select>
                                    <option value={10}>B.ARCH</option>
                                    <option value={20}>B.SC</option>
                                    <option value={30}>MBBS</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-3 box'>
                            <FormControl className='text__outlet'>
                                <InputLabel htmlFor="uncontrolled-native">Sort By</InputLabel>
                                <Select>
                                    <option value={10}>sem 1</option>
                                    <option value={20}>sem 2</option>
                                    <option value={30}>sem 2</option>
                                </Select>
                            </FormControl>
                        </div>

                    </div> */}
                    <div className='row'>
                        <div className='col-md-10 table__top'>
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Employee Name</th>
                                        <th>Employee Code</th>
                                        <th>Employee Group</th>
                                        <th>Attendance</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {userlist.length !== 0 ? userlist.map((data, index) => (
                                        <tr key={index}>
                                            <td> {index + 1}</td>
                                            <td>{data.empolyeename}</td>
                                            <td>{data.empolyeecode}</td>
                                            <td>{data.empolyeegroup}</td>
                                            <td>
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <input type="radio" name={data._id} value="Present" onChange={getattendance} />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <p>Present</p>
                                                    </div>

                                                    <div className='col-md-2'>
                                                        <input type="radio" name={data._id} value="Absent" onChange={getattendance} />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <p>Absent</p>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )) : null}


                                </tbody>
                            </table>
                        </div>
                        <div className='col-md-2'></div>
                    </div>

                    <div className='row'>
                        <div className="col-md-10">
                            <div className='row'>
                                <div className='col-md-10'></div>
                                <div className='col-md-2 box'>
                                    <Button variant="contained" color="primary" onClick={applyattendance}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>

                    </div>


                </div>
            </div>

        </Box>
    )
}

export default Attendance